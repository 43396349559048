import React, { useCallback, useEffect, useRef, useState } from 'react';
import Product from '../pages/products/Product';

import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';
import { StyledProductsContent, StyledPagination } from '../pages/products/styles/ProductsStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { getCookie } from 'cookies-next';
import Link from 'next/link';
import { Container, Row } from 'react-bootstrap';

import messages from '../../../src/messages';
import { FormattedMessage } from 'react-intl';
import DialogAlert from '../global/dialogs/DialogAlert';

SwiperCore.use([Navigation, Pagination, A11y]);

export default function Products({
    props: any,
    openDuplicateDialogAndSetIDZ,
    openDeleteDialogAndSetIDZ,
    openDeleteDialogAndSetIDZMultiple,
    openMoveDialogAndSetIDZ,
    openRenameDialogAndSetIDZ,
    deleteProductCheck,appExternal,
                                     products,
                                     filterActive,

                                     loadProducts,

                                     selectingMultiple,
                                     filterBrands,
                                     filterClients,
                                     filterEditions,
                                     selectingFunction,
                                     totalPages,
                                     currentPage
}) {



    const [threeInited, setThreeInited] = useState(false);

    const [warningMessage, setWarningMessage] = useState("");
    let triggerWarning = (warningMessage) => {
        setWarningMessage(warningMessage);
        handleTriggerWarning();
    };

    let handleTriggerWarning = () => {

    };


    return (
        <div style={{ padding: '0', paddingTop: '15px' }}>
            {}
            {products.length === 0 && !filterActive ? (
                <div data-closemodalmenu={true}>
                    <Container data-closemodalmenu={true}>
                        <Row style={{ paddingBottom: '50px', paddingTop: '50px' }}>
                            <h2 style={{ textAlign: 'center', position: 'relative' }} data-closemodalmenu={true}>
                                <FormattedMessage {...messages.products_welcome_header}></FormattedMessage></h2>


                        </Row>
                        <Row>
                            <div className="welcomeProduct">
                                <Link href={'new-product/?category=e8b3a288-8364-4f5a-b0d0-be7b1e3f3562'}>
                                    <img
                                        style={{
                                            maxWidth: '300px',
                                            top: '-15px',
                                            position: 'relative',
                                            left: ' 0px',
                                        }}
                                        src={'/images/cans.jpg'}
                                    />
                                    <h3 style={{ position: 'relative', top: '5px' }} className="text-center mb-0 mt-1">
                                        <FormattedMessage {...messages.products_type_cans}></FormattedMessage>
                                    </h3>
                                </Link>
                            </div>

                        </Row>
                    </Container>
                </div>
            ) : (
                <>

                </>
            )}

            <StyledProductsContent

                style={products.length === 0 ? { zIndex: '1', position: 'relative' } : null}
                id={'productsContent'}
            >
                <div id="content" style={{ textAlign: 'left' }} data-closemodalmenu={true}>
                    {products.length > 0 && products !== undefined ? (
                        <>
                            {products.map((value, index) => {

                                return (
                                    <Product
                                        appExternal={appExternal}
                                        openDuplicateDialogAndSetIDZ={openDuplicateDialogAndSetIDZ}
                                        openDeleteDialogAndSetIDZ={openDeleteDialogAndSetIDZ}
                                        openMoveDialogAndSetIDZ={openMoveDialogAndSetIDZ}
                                        openRenameDialogAndSetIDZ={openRenameDialogAndSetIDZ}
                                        productType={'GRID'}
                                        withThumbnail={true}
                                        triggerWarning={triggerWarning}
                                        productThumbnail={value.thumbnail.fallback}
                                        threeID={'threeGrid' + value.uuid}
                                        deleteProductCheck={deleteProductCheck}
                                        key={'Product' + index}
                                        productID={value.uuid}
                                        productSelected={value.productSelected}
                                        selectingMultiple={selectingMultiple}
                                        productName={value.name.length > 30 ? value.name.substring(0, 30) + '...' : value.name}
                                        productFullName={value.name}
                                        classes={`showProduct `}
                                        productBrand={filterBrands.map((valueBrands, index) => {
                                            if (valueBrands.uuid === value.groups.brand) {
                                                return valueBrands.name;
                                            }
                                        })}
                                        productClient={filterClients.map((valueClients, index) => {
                                            if (valueClients.uuid === value.groups.client) {
                                                return valueClients.name;
                                            }
                                        })}
                                        productEdition={filterEditions.map((valueEdition, index) => {
                                            if (valueEdition.uuid === value.groups.edition) {
                                                return valueEdition.name;
                                            }
                                        })}
                                        productVersions={value.versions.length}
                                        productVersionsList={value.versions}
                                        productVolume={value.package.volume.name}
                                        productPackageGroup={value.package.groups[0].name}
                                        productPackageLastUUID={value.last_updated_version}
                                        clickEvent={e => selectingFunction(e)}
                                    />
                                );
                            })}
                        </>
                    ) : null}
                </div>
            </StyledProductsContent>

            <DialogAlert
                toggleAlert={ (toggleAlert: () => void) => {
                    handleTriggerWarning = toggleAlert;
                } }
                alertType={"warning"}
                message={warningMessage}
            />

            {totalPages > 1 ? (
                <StyledPagination className={'pagination'}>
                    {currentPage > 1 ? (
                        <button onClick={() => loadProducts(currentPage - 1)}>
                            {' '}
                            <FontAwesomeIcon icon={faChevronLeft} />{' '}
                        </button>
                    ) : null}

                    <span>
                        {currentPage} of {totalPages}
                    </span>

                    {currentPage < totalPages ? (
                        <button onClick={() => loadProducts(currentPage + 1)}>
                            {' '}
                            <FontAwesomeIcon icon={faChevronRight} />{' '}
                        </button>
                    ) : null}
                </StyledPagination>
            ) : null}
        </div>
    );
}
