import React, { useContext, useEffect, useState } from 'react';
import {GlobalButtonFinal, ModalsWrapper, ModalWindow} from "../GlobalStyles";
import {getCookie} from "cookies-next";
import {
    faCheck,
    faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import messages from '../../../../src/messages';
import { FormattedMessage } from 'react-intl';
import * as Sentry from '@sentry/nextjs';
import ObaleroFileUploader from '../ObaleroFileUploader';
import { LangContext } from '../../../../src/ConnectedIntlProvider';


export default function DialogGroupsModalEditAdd ({closeModalOutsideClickEdit,modalTransitionShow,
                                                      editAllModalActive,
                                                      editionsModalActive,
                                                      clientModalActive,
                                                      brandModalActive,
                                                      editAllName,
                                                      editAllTypeHeaderText,
                                                      editAllType,
                                                      editAllIcon,
                                                      editAllUUID,
                                                      setModalShow,
                                                      loadProductsFilters,
                                                      filterClients,
                                                      changeEditAllName,
                                                      filterBrands,
                                                            parentBrand,
                                                      parentClient,

                                                      triggerWarning
}) {
    const { language, setLanguage } = useContext(LangContext);

    const onChangeHandlerEdit = ((e: React.ChangeEvent<HTMLInputElement>): void => {
        if(e.currentTarget.value.length < 14){
            changeEditAllName(e.currentTarget.value);
        }
    });

    const [parentBrandInner, changeParentBrand] = useState(null);
    const handleParentBrand = ((e: React.ChangeEvent<HTMLInputElement>): void => {

        changeParentBrand(e.currentTarget.value);

    });


    const [parentClientInner, changeParentClient] = useState(null);
    const handleParentClient = ((e: React.ChangeEvent<HTMLInputElement>): void => {

        console.log("CHANGINR PARENT CLIENT",e.currentTarget.value );

            changeParentClient(e.currentTarget.value === "" ? null : e.currentTarget.value);

    });








    const fileTypes = ["JPG", "PNG"];
    const [file, setFile] = useState(null);



    function editAPIUploadLogo() {

        if(editAllName.length > 3){

            if(file !== null){
                fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"uploads/prepare-form", {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': "Bearer "+  getCookie('userTeam'), // notice the Bearer before your token
                    },
                })
                    .then(response => response.json())
                    .then(function(data){

                        if(data.message !== undefined){
                            Sentry.captureException("uploads/prepare-form"+": "+ data.message + " : " + getCookie("userMail"));
                        }



                        let formData = new FormData();

                        Object.keys(data.formInputs).forEach((key) => {
                            formData.append(key, data.formInputs[key]);
                        });

                        formData.append('file', file);

                        fetch(data.formAttributes.action, {
                            method: data.formAttributes.method,
                            body: formData,
                        })
                            .then(function(dataUpload){
                                editAPI("success", "/"+data.formInputs.key);

                            })
                            .catch(function(e){
                                Sentry.captureException(data.formAttributes.action+": "+ data.message + " : " + getCookie("userMail"));
                            });


                    });
            } else {
                editAPI("success", null);
            }

        } else {
            if(language === "cs"){
                triggerWarning("Krátké jméno");
            } else{
                triggerWarning("Short name");
            }

        }


    }


    const editAPI = (response, key) => {


            var parent = null;

            if(editAllType === "brand"){
                parent = parentClientInner;
            }
            if(editAllType === "edition"){
                parent = parentBrandInner;
            }




            if(response === "success"){

                fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"groups/"+editAllUUID, {
                    method: 'PUT',
                    body: JSON.stringify(    {
                        name : editAllName,
                        logo : key,
                        type: editAllType ? editAllType : null,
                        parent:parent
                    }),
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
                    },
                })
                    .then(response => response.json())
                    .then(function(data){


                        if(data.message !== undefined){
                            Sentry.captureException("edit groups/"+editAllUUID+": "+ data.message + " : " + getCookie("userMail"));


                            if(editAllName === null || editAllName === ""){

                                if(language === "en"){
                                    if(triggerWarning){
                                        triggerWarning("Please fill the name");
                                    }

                                } else{
                                    if(triggerWarning){
                                        triggerWarning("Vyplňte prosím jméno");
                                    }

                                }


                            } else {
                                if(triggerWarning){
                                    triggerWarning(data.message);
                                }

                            }

                        }

                        if(data.message === undefined){

                            changeEditAllName("");
                            setFile(null);
                            closeModalShow();
                            loadProductsFilters();


                        }

                    });

            }


    }


    const closeModalShow = () => {
        setFile(null);
        setModalShow(false, "client");
        setModalShow(false, "brand");
        setModalShow(false, "edition");
        setModalShow(false, "editAll");
    };

    const handleChange = (file) => {
        setFile(file);
    };

    const [canClick, setCanClick] = useState(true);

    function AddGroup(groupType) {



        if(inputName.length > 3){

            setCanClick(false);
            if(file !== null){
                fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"uploads/prepare-form", {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': "Bearer "+  getCookie('userTeam'), // notice the Bearer before your token
                    },
                })
                    .then(response => response.json())
                    .then(function(data){

                        if(data.message !== undefined){
                            Sentry.captureException("edit uploads/prepare-form"+": "+ data.message + " : " + getCookie("userMail"));
                            triggerWarning(data.message);
                        }

                        let formData = new FormData();

                        Object.keys(data.formInputs).forEach((key) => {
                            formData.append(key, data.formInputs[key]);
                        });
                        formData.append('file', file);

                        fetch(data.formAttributes.action, {
                            method: data.formAttributes.method,
                            // headers: {
                            //     'Content-type': data.formAttributes.enctype
                            // },
                            body: formData,
                        })
                            .then(function(dataUpload){
                                getResponseNewGroupAPI("success", "/"+data.formInputs.key, groupType);

                            })
                            .catch(function(e){
                                Sentry.captureException(data.formAttributes.action+": "+ data.message + " : " + getCookie("userMail"));
                            });


                        // uploadLogo(file, data.formInputs.key, groupType,getResponseNewGroupAPI)

                    });
            } else {
                getResponseNewGroupAPI("success", null,groupType);
            }

        } else {
            if(language === "cs"){
                triggerWarning("Krátké jméno");
            } else{
                triggerWarning("Short name");
            }

        }



    }


    const getResponseNewGroupAPI = (response, key, type) => {

        var parent = null;

        if(type === "brand"){
            parent = parentClientInner;
        }
        if(type === "edition"){
            parent = parentBrandInner;
        }

        if(response === "success"){

            fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"groups", {
                method: 'POST',
                body: JSON.stringify(    {
                    name : inputName,
                    logo : key,
                    type: type ? type : null,
                    parent: parent
                }),
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
                },
            })
                .then(response => response.json())
                .then(function(data){
                    setCanClick(true);
                    if(data.message !== undefined){
                        Sentry.captureException("groups"+": "+ data.message + " : " + getCookie("userMail"));


                        if(inputName === null || inputName === ""){

                            if(language === "en"){
                                triggerWarning("Please fill the name");
                            } else{
                                triggerWarning("Vyplňte prosím jméno");
                            }


                        } else {
                            triggerWarning(data.message);
                        }


                    }

                    if(data.message === undefined){
                        changeInputName("");
                        setFile(null);
                      closeModalShow();

                        loadProductsFilters();

                    }

                });
        }
    }

    const [inputName, changeInputName] = useState("");

    const onChangeHandler = ((e: React.ChangeEvent<HTMLInputElement>): void => {
        if(e.currentTarget.value.length < 14){
            changeInputName(e.currentTarget.value);
        }
    });

    const [editAllIconInner, setEditAllIcon] = useState(null);


    useEffect(() => {


        var neff;
        if(file){
            neff = URL.createObjectURL(file);
        } else {
            neff = null
        }
        console.log(editAllIcon, "editAllIcon");
        console.log("neff", neff);

        if(editAllIcon !== null && editAllIcon !== neff){
            console.log("settigngn", editAllIcon);
            setEditAllIcon(editAllIcon);
        }
        if(editAllIcon === null){
            console.log("settigngn mnull", editAllIcon);
            setEditAllIcon(null);
        }
    }, [editAllIcon]);

    useEffect(() => {

        if(parentClient !== null && parentClient!== parentClientInner){
            changeParentClient(parentClient);
        }
    }, [parentClient]);

    useEffect(() => {

      if(parentBrand !== null && parentBrand!== parentBrandInner){
          changeParentBrand(parentBrand);
      }
    }, [parentBrand]);


    useEffect(() => {

        if(parentClientInner !== null && parentClient!== parentClientInner){

            changeParentClient(parentClientInner);
        }
    }, [parentClientInner]);

    useEffect(() => {

        if(parentBrandInner !== null && parentBrand!== parentBrandInner){

            changeParentBrand(parentBrandInner);
        }
    }, [parentBrandInner]);

    return (
        <>

            <ModalsWrapper
                data-closemodal={true}
                onClick={(e)=> closeModalOutsideClickEdit(e)}
                style={{    zIndex: "999999999999" }}
                className={` ${modalTransitionShow ? 'showTransition' : 'hideTransition'}
                 ${(editAllModalActive || editionsModalActive || clientModalActive||  brandModalActive) ? '' : 'hiddeComplete'} `}>

                <ModalWindow id={'editAllModal'} className={` modal_dialog_small ${editAllModalActive ? '' : 'hideModal'} `} >
                    <div className={'modal-header'} >
                        <h4>
                            <FormattedMessage {...messages.products_modal_groups_edit_title}></FormattedMessage> {editAllName}
                        </h4>
                    </div>


                    <div className={'modal-content editGroups'} style={{display:"block", paddingTop:"15px"}}>


                        {editAllTypeHeaderText === "Clients" ?
                           <h5  style={{marginBottom: "0!important"}}><FormattedMessage {...messages.products_filter_clients}></FormattedMessage> </h5> : null}
                        {editAllTypeHeaderText === "Brands" ?
                           <h5  style={{marginBottom: "0!important"}}> <FormattedMessage {...messages.products_filter_brands}></FormattedMessage> </h5> : null}
                        {editAllTypeHeaderText === "Editions" ?
                            <h5 style={{marginBottom: "0!important"}}>  <FormattedMessage {...messages.products_filter_editions}></FormattedMessage></h5> : null}

                        <input  style={{width: "97%"}}
                                id={"editGroupInput"}
                                onChange={onChangeHandlerEdit}
                                type={'text'} placeholder={editAllName}  value={editAllName} />

                        {editAllType === "brand" ?
                            <div style={{ width: '100%' }}>
                                <h5 style={{marginTop:"15px" }}><FormattedMessage {...messages.products_filter_clients}></FormattedMessage></h5>
                                <select name="clientInBrandSelect" id="clientInBrandSelect" value={parentClientInner}
                                        onChange={handleParentClient}
                                >
                                    {/*@ts-ignore*/}
                                    {filterClients && filterClients.length > 0 ? (
                                        <>
                                            <option  key={""} value={""}>{"No client"}</option>
                                            {filterClients.map((value, index) => {
                                                return <option  key={value.uuid} value={value.uuid}>{value.name}</option>;
                                            })}
                                        </>
                                    ) : null}
                                </select>
                            </div>
                            : null}

                        {editAllType === "edition" ?
                            <div style={{ width: '100%' }}>
                                <h5 style={{marginTop:"15px" }}><FormattedMessage {...messages.products_filter_brands}></FormattedMessage></h5>
                                <select name="clientInBrandSelect" id="clientInBrandSelect" value={parentBrandInner}
                                        onChange={handleParentBrand}
                                >
                                    {/*@ts-ignore*/}
                                    {filterBrands && filterBrands.length > 0 ? (
                                        <>
                                            <option key="" value={""}>{"No brand"}</option>
                                            {filterBrands.map((value, index) => {
                                                return <option key={value.uuid} value={value.uuid}>{value.name}</option>;
                                            })}
                                        </>
                                    ) : null}
                                </select>
                            </div>
                            : null}


                        <div style={{  width: "25%",   display: "inline-block",     position: "relative", top: "35px"}}>
                            <h5>
                                <FormattedMessage {...messages.products_modal_groups_edit_icon_title}></FormattedMessage></h5>
                            {file || editAllIconInner?
                                <img style={{ border: '1px solid lightgray', maxWidth: '100%' }} src={editAllIconInner ? editAllIconInner : URL.createObjectURL(file)} />
                                : <img style={{ opacity:"50%", maxWidth: '100%' }} src={"/images/logo.svg"} />}

                        </div>


                        <div style={{  width: "71.6%",   display: "inline-block"}}>


                            <ObaleroFileUploader setFile={setFile} file={file} isSmall={true}/>
                        </div>

                      {/*  <br/>
                        <span style={{textAlign:"left",marginTop:"15px", marginBottom:"15px"}}>
                             <FormattedMessage {...messages.products_modal_groups_edit_color_description}></FormattedMessage> </span>

                        <div className={"colors"}>
                            <button
                                style={{background: "#ffffff"}}
                            >
                            </button>
                            <button
                                style={{background: "#000000"}}
                            >
                            </button>
                            <button
                                style={{background: "#ff0000"}}
                            >
                            </button>
                            <button
                                style={{background: "#0300ff"}}
                            >
                            </button>
                            <button
                                style={{background: "#00ff1d"}}
                            >
                            </button>
                            <button
                                style={{background: "#ff9300"}}
                            >
                            </button>


                        </div>
*/}
                        <div className={"modal-buttons"} style={{  width: "100%", display:"block", textAlign: "center",position:"relative", marginTop :"15px", left:"-5px"}}>

                            <GlobalButtonFinal onClick={() =>  closeModalShow()} className={"borderBlue btn-circle"}
                                               >
                                <FormattedMessage {...messages.products_modal_groups_edit_button_cancel}></FormattedMessage>
                            </GlobalButtonFinal>

                            <GlobalButtonFinal  onClick={() => editAPIUploadLogo()} className={"backgroundBlue btn-circle"}
                                                id={"editGroupSave"}
                                               >
                                <FontAwesomeIcon   icon={faCheck}/>       <FormattedMessage {...messages.products_modal_groups_edit_button_save}></FormattedMessage>
                            </GlobalButtonFinal>

                        </div>

                    </div>
                </ModalWindow>


                <ModalWindow id={'clientModal'}
                             className={` modal_dialog_small ${clientModalActive ? '' : 'hideModal'} `}
                             style={{ minHeight: '330px' }}>
                    <div className={'modal-header'}>
                        <h4><FormattedMessage {...messages.products_modal_groups_client_add_title}></FormattedMessage></h4>

                    </div>


                    <div className={'modal-content editGroups'} style={{ display: 'block', paddingTop: '15px' }}>
                        <h5 style={{ marginTop: '5px' }}><FormattedMessage {...messages.products_modal_groups_add_input_name_title}></FormattedMessage> </h5>
                        <input id={'addNewClientName'} type={'text'}
                               style={{ width: '97%' }}
                               value={inputName} onChange={onChangeHandler} />


                        <div style={{ width: '25%', display: 'inline-block',    position: "relative", top: "34px" }}>
                            <h5><FormattedMessage {...messages.products_modal_groups_add_icon_title}></FormattedMessage></h5>
                            {file  || editAllIconInner?
                                <img style={{ border: '1px solid lightgray', maxWidth: '100%' }} src={editAllIconInner ? editAllIconInner : URL.createObjectURL(file)} />
                                : <img style={{ opacity:"50%", maxWidth: '100%' }}
                                       src={'/images/logo.svg'} />}

                        </div>


                        <div style={{ width: '71.6%', display: 'inline-block' }}>


                            <ObaleroFileUploader setFile={setFile} file={file} isSmall={true} />
                        </div>


                      {/*  <span style={{
                            textAlign: 'left',
                            marginTop: '15px',
                            marginBottom: '15px',
                        }}><FormattedMessage {...messages.products_modal_groups_add_color_description}></FormattedMessage></span>

                        <div className={'colors'}>
                            <button
                                style={{ background: '#ffffff' }}
                            >
                            </button>
                            <button
                                style={{ background: '#000000' }}
                            >
                            </button>
                            <button
                                style={{ background: '#ff0000' }}
                            >
                            </button>
                            <button
                                style={{ background: '#0300ff' }}
                            >
                            </button>
                            <button
                                style={{ background: '#00ff1d' }}
                            >
                            </button>
                            <button
                                style={{ background: '#ff9300' }}
                            >
                            </button>


                        </div>*/}


                        <div className={'modal-buttons'} style={{ width: '100%' }}>
                            <GlobalButtonFinal className={'borderBlue btn-circle'} onClick={() => closeModalShow()}>
                                <FormattedMessage {...messages.products_modal_groups_add_button_cancel}></FormattedMessage>
                            </GlobalButtonFinal>
                            <GlobalButtonFinal id={'saveNewClient'} className={'backgroundBlue btn-circle'}
                                               onClick={canClick ? () => AddGroup('client') : null}>
                                <FontAwesomeIcon icon={faPlus} /> <FormattedMessage {...messages.products_modal_groups_add_button_add}></FormattedMessage>
                            </GlobalButtonFinal>

                        </div>
                    </div>
                </ModalWindow>

                <ModalWindow id={'brandModal'}
                             className={` modal_dialog_small ${brandModalActive ? '' : 'hideModal'} `}>

                    <div className={'modal-header'}>

                        <h4>
                            <FormattedMessage {...messages.products_modal_groups_brand_add_title}></FormattedMessage>

                        </h4>

                    </div>


                    <div className={'modal-content editGroups'} style={{ display: 'block', paddingTop: '15px' }}>

                        <h5 style={{ marginTop: '5px' }}>               <FormattedMessage {...messages.products_modal_groups_add_input_name_title}></FormattedMessage></h5>
                        <input style={{ width: '97%' }} type={'text'}
                               value={inputName} onChange={onChangeHandler} />



                            <div style={{ width: '100%' }}>
                                <h5 style={{ marginTop: '15px' }}> <FormattedMessage {...messages.products_modal_groups_edit_client_title}></FormattedMessage> </h5>
                                <select name="clientInBrandSelect" id="clientInBrandSelect" value={parentClientInner}
                                        onChange={handleParentClient}
                                >
                                    {/*@ts-ignore*/}
                                    {filterClients && filterClients.length > 0 ? (
                                        <>
                                            <option  key={""} value={""}>{'No client'}</option>
                                            {filterClients.map((value, index) => {
                                                return <option  key={value.uuid} value={value.uuid}>{value.name}</option>;
                                            })}
                                        </>
                                    ) : null}
                                </select>
                            </div>



                        <div style={{ width: '25%', display: 'inline-block',    position: "relative",   top: "34px" }}>
                            <h5>  <FormattedMessage {...messages.products_modal_groups_add_icon_title}></FormattedMessage> </h5>
                            {file  || editAllIconInner?
                                <img style={{ border: '1px solid lightgray',  maxWidth: '100%' }} src={editAllIconInner ? editAllIconInner : URL.createObjectURL(file)} />
                                : <img style={{ opacity:"50%", maxWidth: '100%' }}
                                       src={'/images/logo.svg'} />}

                        </div>


                        <div style={{ width: '71.6%', display: 'inline-block' }}>


                            <ObaleroFileUploader setFile={setFile} file={file} isSmall={true} />
                        </div>


                 {/*       <span style={{
                            textAlign: 'left',
                            marginTop: '15px',
                            marginBottom: '15px',
                        }}><FormattedMessage {...messages.products_modal_groups_add_color_description}></FormattedMessage> </span>


                        <div className={'colors'}>
                            <button
                                style={{ background: '#ffffff' }}
                            >
                            </button>
                            <button
                                style={{ background: '#000000' }}
                            >
                            </button>
                            <button
                                style={{ background: '#ff0000' }}
                            >
                            </button>
                            <button
                                style={{ background: '#0300ff' }}
                            >
                            </button>
                            <button
                                style={{ background: '#00ff1d' }}
                            >
                            </button>
                            <button
                                style={{ background: '#ff9300' }}
                            >
                            </button>


                        </div>*/}


                        <div className={'modal-buttons'} style={{ width: '100%' }}>
                            <GlobalButtonFinal className={'borderBlue btn-circle'} onClick={() => closeModalShow()}>
                                <FormattedMessage {...messages.products_modal_groups_add_button_cancel}></FormattedMessage>
                            </GlobalButtonFinal>

                            <GlobalButtonFinal className={'backgroundBlue btn-circle'}
                                               onClick={canClick ? () => AddGroup('brand') : null}>
                                <FontAwesomeIcon icon={faPlus} />   <FormattedMessage {...messages.products_modal_groups_add_button_add}></FormattedMessage>
                            </GlobalButtonFinal>
                        </div>

                    </div>
                </ModalWindow>

                <ModalWindow id={'editionsModal'}
                             className={` modal_dialog_small ${editionsModalActive ? '' : 'hideModal'} `}>


                    <div className={'modal-header'}>

                        <h4>
                            <FormattedMessage {...messages.products_modal_groups_edition_add_title}></FormattedMessage> </h4>

                    </div>


                    <div className={'modal-content editGroups'} style={{ display: 'block', paddingTop: '15px' }}>

                        <h5 style={{ marginTop: '5px' }}>   <FormattedMessage {...messages.products_modal_groups_add_input_name_title}></FormattedMessage> </h5>

                        <input style={{ width: '97%' }} type={'text'}
                               value={inputName} onChange={onChangeHandler} />

                      {/*  <div style={{ width: '48%', display: 'inline-block' }}>
                            <h5 style={{ marginTop: '15px' }}><FormattedMessage {...messages.products_modal_groups_edit_client_title}></FormattedMessage></h5>
                            <select name="clientInBrandSelect" id="clientInBrandSelect" value={parentClient}
                                    onChange={handleParentClient}
                            >
                                {filterClients && filterClients.length > 0 ? (
                                    <>
                                        <option value={null}><FormattedMessage {...messages.products_modal_groups_edit_no_client_title}></FormattedMessage></option>
                                        {filterClients.map((value, index) => {
                                            return <option value={value.uuid}>{value.name}</option>;
                                        })}
                                    </>
                                ) : null}
                            </select>
                        </div>*/}

                        <div style={{ width: '100%', display: 'inline-block' }}>
                            <h5 style={{ marginTop: '15px' }}><FormattedMessage {...messages.products_modal_groups_edit_brand_title}></FormattedMessage></h5>
                            <select name="clientInBrandSelect" id="clientInBrandSelect" value={parentBrandInner}
                                    onChange={handleParentBrand}
                            >
                                {/*@ts-ignore*/}
                                {filterBrands && filterBrands.length > 0 ? (
                                    <>
                                        <option  key={""} value={""}><FormattedMessage {...messages.products_modal_groups_edit_no_brand_title}></FormattedMessage></option>
                                        {filterBrands.map((value, index) => {
                                            return <option  key={value.uuid} value={value.uuid}>{value.name}</option>;
                                        })}
                                    </>
                                ) : null}
                            </select>
                        </div>


                        <div style={{ width: '25%', display: 'inline-block',     position: "relative",  top: "34px" }}>
                            <h5><FormattedMessage {...messages.products_modal_groups_edit_icon_title}></FormattedMessage></h5>
                            {file || editAllIconInner ?
                                <img style={{ opacity:"50%", maxWidth: '100%' }} src={editAllIconInner ? editAllIconInner : URL.createObjectURL(file)} />
                                : <img style={{ border: '1px solid lightgray', maxWidth: '100%' }}
                                       src={'/images/logo.svg'} />}

                        </div>

                        <div style={{ width: '71.6%', display: 'inline-block' }}>


                            <ObaleroFileUploader setFile={setFile} file={file} isSmall={true} />
                        </div>


                     {/*   <span style={{
                            textAlign: 'left',
                            marginTop: '15px',
                            marginBottom: '15px',
                        }}><FormattedMessage {...messages.products_modal_groups_edit_color_description}></FormattedMessage></span>

                        <div className={'colors'}>
                            <button
                                style={{ background: '#ffffff' }}
                            >
                            </button>
                            <button
                                style={{ background: '#000000' }}
                            >
                            </button>
                            <button
                                style={{ background: '#ff0000' }}
                            >
                            </button>
                            <button
                                style={{ background: '#0300ff' }}
                            >
                            </button>
                            <button
                                style={{ background: '#00ff1d' }}
                            >
                            </button>
                            <button
                                style={{ background: '#ff9300' }}
                            >
                            </button>


                        </div>
*/}
                        <div className={'modal-buttons'} style={{ width: '100%' }}>

                            <GlobalButtonFinal className={'borderBlue btn-circle'} onClick={() => closeModalShow()}>
                                <FormattedMessage {...messages.products_modal_groups_edit_button_cancel}></FormattedMessage>
                            </GlobalButtonFinal>
                            <GlobalButtonFinal className={'backgroundBlue btn-circle'}
                                               onClick={canClick ? () => AddGroup('edition') : null}>
                                <FontAwesomeIcon icon={faPlus} />  <FormattedMessage {...messages.products_modal_groups_add_button_add}></FormattedMessage>
                            </GlobalButtonFinal>
                        </div>
                    </div>
                </ModalWindow>

            </ModalsWrapper>


        </>
    )
}