import React, { useEffect, useState } from 'react';
import { StyledProductFilter } from './styles/ProductsStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMugHot, faPen, faPlus, faTrash,faChevronUp,
    faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import messages from '../../../../src/messages';
import { GlobalButtonFinal, ModalsWrapper, ModalWindowRightSide } from '../../global/GlobalStyles';
import { getCookie, setCookie } from 'cookies-next';

import * as Sentry from '@sentry/nextjs';
import DialogGroupsModalEditAdd from '../../global/dialogs/DialogGroupsModalEditAdd';
import { useRouter } from 'next/router';
import DialogDeleteGroups from '../../global/dialogs/DialogDeleteGroups';
import DialogAlert from '../../global/dialogs/DialogAlert';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';

export default function GroupsFilter({
    props: any,
    type,
    setLoadProductsShow,
    loadProducts,
    filterActive,
    loadinProductsComplete,
    setFilterActive,
    setFilterClientsProducts,
    setFilterBrandsProducts,
    setFilterEditionsProducts,
    disabledFilters

}) {
    const [allFilterDataAPI, setAllFilterDataAPI] = useState({});

    const [originalDataAPI, setOriginalDataAPI] = useState({});

    const [filterClients, setFilterClients] = useState([]);
    const [filterBrands, setFilterBrands] = useState([]);
    const [filterEditions, setFilterEditions] = useState([]);
    const [filterProductTypes, setFilterProductTypes] = useState([]);
    const [filterSizes, setFilterSizes] = useState([]);

    const [filterClientsOpened, changeClientFilterOpen] = useState(false);
    const [filterBrandsOpened, changeBrandsFilterOpen] = useState(false);
    const [filterEditionsOpened, changeEditionsFilterOpen] = useState(false);
    const [filterProductTypesOpened, changeProductTypesFilterOpen] = useState(false);
    const [filterSizesOpened, changeSizesFilterOpen] = useState(false);

    const [activeFilterClients, setActiveFilterClients] = useState([]);

    const [activeFilterBrands, setActiveFilterBrands] = useState([]);

    const [activeFilterEditions, setActiveFilterEditions] = useState([]);
    const [activeFilterProductTypes, setActiveFilterProductTypes] = useState([]);
    const [activeFilterSizes, setActiveFilterSizes] = useState([]);

    const router = useRouter();

    const [clientModalActive, changeClientModal] = useState(false);
    const [brandModalActive, changeBrandModal] = useState(false);
    const [editionsModalActive, changeEditionsModal] = useState(false);
    const [editAllModalActive, changeEditAllModalActive] = useState(false);
    const [editAllTypeHeaderText, changeEditAllTypeHeaderText] = useState(false);
    //
    // modalTransitionShowActiveFilterSide
    // activeFilterSide
    // activeFilterSide

    const [defaultFilteredValues, setDefaultFilteredValues] = useState({});

    const [activeFilterSide, setActiveFilterSide] = useState(false);
    const [modalTransitionShowActiveFilterSide, setModalTransitionShowActiveFilterSide] = useState(false);
    const changeActiveFilterSide = variable => {
        if (variable === true) {
           // loadProductsFiltersAPI();
            setActiveFilterSide(variable);
            setTimeout(function () {
                setModalTransitionShowActiveFilterSide(true);
            }, 1);
        } else {
            setModalTransitionShowActiveFilterSide(false);
            setTimeout(function () {
                changeClientFilterOpen(false);
                changeBrandsFilterOpen(false);
                changeEditionsFilterOpen(false);
                changeProductTypesFilterOpen(false);
                changeSizesFilterOpen(false);
                setActiveFilterSide(variable);
            }, 500);
        }
    };

    const [modalTransitionShow, setModalTransitionShow] = useState(false);

    const showDelete = uuid => {
        handleClickDelete();
        changeEditAllUUID(uuid);
        changeEditAllDeletecheck(true);
        //  setModalTransitionShow(true);
    };

    const [editAllDeletecheck, changeEditAllDeletecheck] = useState(false);
    const [editAllName, changeEditAllName] = useState('');
    const [editAllType, changeEditAllType] = useState('');
    const [editAllIcon, changeEditAllIcon] = useState('');
    const [editAllUUID, changeEditAllUUID] = useState('');

    const [clientPlaceholderText, setClientPlaceholderText] = useState(messages.products_filter_clients.defaultMessage);
    const [brandPlaceholderText, setBrandPlaceholderText] = useState(messages.products_filter_brands.defaultMessage);
    const [editionPlaceholderText, setEditionPlaceholderText] = useState(messages.products_filter_editions.defaultMessage);

    const [parentClient, setParentClient] = useState(null);
    const [parentBrand, setParentBrand] = useState(null);

    const [isOpener, setIsOpener] = useState(false);
    const [isOpen, setIsOpen] = useState(false);


    const startEditAnything = (type, uuid, editName, logo, parentUUID) => {
        changeEditAllName(editName);
        changeEditAllIcon(logo);
        changeEditAllUUID(uuid);
        changeEditAllType(type);


        if (type === 'client') {
            changeEditAllTypeHeaderText(clientPlaceholderText);
        }
        if (type === 'brand') {
            changeEditAllTypeHeaderText(brandPlaceholderText);

            if(parentUUID){
                setParentClient(parentUUID);
            }
        }
        if (type === 'edition') {
            changeEditAllTypeHeaderText(editionPlaceholderText);
            if(parentUUID){
                setParentBrand(parentUUID);
            }
        }

        setModalShow(true, 'editAll');
    };

    const setModalShow = (variable, type) => {
        if (variable === true) {
            // setLoadProductsShowVariable(variable);

            if (type === 'client') {
                changeClientModal(true);
                setTimeout(function () {
                    setModalTransitionShow(true);
                }, 1);
            }
            if (type === 'brand') {
                changeBrandModal(true);
                setTimeout(function () {
                    setModalTransitionShow(true);
                }, 1);
            }
            if (type === 'edition') {
                changeEditionsModal(true);
                setTimeout(function () {
                    setModalTransitionShow(true);
                }, 1);
            }
            if (type === 'editAll') {
                changeEditAllModalActive(true);

                setTimeout(function () {
                    setModalTransitionShow(true);
                }, 1);
            }
        } else {
            setModalTransitionShow(false);
            setTimeout(function () {
                changeClientModal(false);
                changeBrandModal(false);
                changeEditionsModal(false);
                changeEditAllModalActive(false);

                //setLoadProductsShowVariable(variable);
            }, 300);
        }
    };

    const [openedFilterName, setOpenedFilterName] = useState('Clients');

    const openFilter = filter => {

        setOriginalDataAPI(JSON.parse(JSON.stringify(allFilterDataAPI)));
        setOriginalSelectedValues(JSON.parse(JSON.stringify(selectedValues)));
        changeClientFilterOpen(false);
        changeBrandsFilterOpen(false);
        changeEditionsFilterOpen(false);
        changeProductTypesFilterOpen(false);
        changeSizesFilterOpen(false);

        changeActiveFilterSide(true);

        if (filter === 'clients') {
            setOpenedFilterName('Clients');

            changeClientFilterOpen(true);
        }
        if (filter === 'brands') {
            changeBrandsFilterOpen(true);
            setOpenedFilterName('Brands');
        }
        if (filter === 'editions') {
            changeEditionsFilterOpen(true);
            setOpenedFilterName('Editions');
        }
        if (filter === 'productTypes') {
            changeProductTypesFilterOpen(true);
            setOpenedFilterName('Product types');
        }
        if (filter === 'sizes') {
            changeSizesFilterOpen(true);
            setOpenedFilterName('Sizes');
        }
    };

    const removeFilterGroup = (uuid, typeGroup) => {
        var selected = JSON.parse(JSON.stringify(selectedValues));

        var selectedValuesArr = { ...selectedValues };

        var type = typeGroup;
        var dataAPI = { ...allFilterDataAPI };
        var countCheckboxes = 0;
        var valuesInner = selectedValuesArr[typeGroup];
        var nef = [];


        if (valuesInner !== undefined) {
            Object.keys(dataAPI).forEach(filterKey => {
                if (filterKey === 'values') {
                    Object.keys(dataAPI[filterKey]).forEach(filterKeyValues => {
                        if (filterKeyValues === typeGroup) {
                            Object.keys(dataAPI[filterKey][filterKeyValues]).forEach(filterKeyValuesInside => {
                                if (dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].uuid === uuid) {
                                    if (dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked) {



                                        function removeValueFromObject(obj, key, value) {
                                            const index = obj[key].indexOf(value);
                                            if (index !== -1) {
                                                obj[key].splice(index, 1);
                                            }
                                            return obj;
                                        }

                                        const newObject = removeValueFromObject(selected, type, uuid);

                                        if(newObject[typeGroup].length === 0){
                                            delete newObject[typeGroup];
                                        }

                                        nef = newObject;
                                      dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked = false;
                                    } else {

                                        dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked = true;
                                    }
                                }

                                if (dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked) {
                                    countCheckboxes++;
                                }
                            });
                        }
                    });
                }
            });
        }

        if (Object.keys(selectedValuesArr).length === 0) {
            setActiveFilterClients([]);
            setActiveFilterBrands([]);
            setActiveFilterEditions([]);
            setActiveFilterProductTypes([]);
            setActiveFilterSizes([]);
            setSelectedValues({});
            simplifyAllValues();
            return;
        }

        if (Object.values(selectedValuesArr).indexOf(uuid) > -1) {
            delete selectedValuesArr[typeGroup];
        }


        if (Object.keys(nef).length > 0) {
            setSelectedValues(nef);
            showFilteredProducts(nef);
        } else {
            setSelectedValues([]);

            setValues([]);

            showFilteredProducts([]);
        }
    };


    const showFilteredProducts = selectedValuesArr => {
        var newValues = selectedValues;

        if (selectedValuesArr) {
            newValues = selectedValuesArr;
        }

        loadProducts(null, newValues);


        let filtersVariableStringClients = '&clients=';
        let filtersVariableStringBrands = '&brands=';
        let filtersVariableStringEditions = '&editions=';
        let filtersVariableStringProductTypes = '&product_types=';
        let filtersVariableStringPackages = '&packages=';
        let filtersVariableStringVolumes = '&volumes=';

        if (newValues.clients && newValues.clients.length > 0) {

            filtersVariableStringClients += newValues.clients.toString();
        } else {
            filtersVariableStringClients = '';
        }
        if (newValues.brands && newValues.brands.length > 0) {
            filtersVariableStringBrands += newValues.brands.toString();
        } else {
            filtersVariableStringBrands = '';
        }
        if (newValues.editions && newValues.editions.length > 0) {
            filtersVariableStringEditions += newValues.editions.toString();
        } else {
            filtersVariableStringEditions = '';
        }
        if (newValues.product_types && newValues.product_types.length > 0) {
            filtersVariableStringProductTypes += newValues.product_types.toString();
        } else {
            filtersVariableStringProductTypes = '';
        }
        if (newValues.packages && newValues.packages.length > 0) {
            filtersVariableStringPackages += newValues.packages.toString();
        } else {
            filtersVariableStringPackages = '';
        }
        if (newValues.volumes && newValues.volumes.length > 0) {
            filtersVariableStringVolumes += newValues.volumes.toString();
        } else {
            filtersVariableStringVolumes = '';
        }

        var fullVariableString =
            filtersVariableStringClients +
            filtersVariableStringBrands +
            filtersVariableStringEditions +
            filtersVariableStringProductTypes +
            filtersVariableStringPackages +
            filtersVariableStringVolumes;


        if (fullVariableString.length === 0) {
            window.history.replaceState(null, 'Products', '/');
        } else {
            //  setFilterActive(true);
            window.history.replaceState(null, 'Products', '/?' + fullVariableString);
        }




        changeActiveFilterSide(false);
        setOriginalDataAPI(JSON.parse(JSON.stringify(allFilterDataAPI)));
        setOriginalSelectedValues(JSON.parse(JSON.stringify(newValues)));

        /*  if((filteredValues.clients && filteredValues.clients.length > 0 ) ||
            (filteredValues.brands&& filteredValues.brands.length > 0) ||
            (filteredValues.editions && filteredValues.editions.length > 0) ||
            (filteredValues.product_types && filteredValues.product_types.length) > 0 ||
            (filteredValues.volumes && filteredValues.volumes.length) > 0
        ){
            setFilterActive(true);
        }else {
            setFilterActive(false);
        }*/

        setTimeout(function () {
            setLoadProductsShow(false);
        }, 1000);
    };

    const onLoadAddFilter = (valuesAll) => {
        // clients uuid

        // var valuesInner = values;
        var selected = JSON.parse(JSON.stringify(selectedValues));

        var selectedValuesArr = { ...selectedValues };
        var dataAPI = { ...allFilterDataAPI };
        var countCheckboxes = 0;

        var nef = {};



        Object.keys(valuesAll).forEach(function(key, idx, arr){

            valuesAll[key].forEach(element => {
                Object.keys(dataAPI).forEach(filterKey => {
                    if (filterKey === 'values') {
                        Object.keys(dataAPI[filterKey]).forEach(filterKeyValues => {
                            Object.keys(dataAPI[filterKey][filterKeyValues]).forEach(filterKeyValuesInside => {
                                if (filterKeyValues === key) {

                                    if (dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].uuid === element) {
                                        if (dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked) {
                                            function removeValueFromObject(obj, key, value) {
                                                const index = obj[key].indexOf(value);
                                                if (index !== -1) {
                                                    obj[key].splice(index, 1);
                                                }
                                                return obj;
                                            }
                                            const newObject = removeValueFromObject(selected, key, element);
                                            nef = newObject;
                                            dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked = false;
                                        } else {


                                            if(Array.isArray(selected[key])){
                                                selected[key].push( dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].uuid);
                                            } else {
                                                selected[key] = [ dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].uuid];
                                            }
                                            nef = selected;
                                            dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked = true;
                                        }
                                    }
                                }

                                if (dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked) {
                                    countCheckboxes++;
                                }
                            });
                        });
                    }
                });
            });

        });




        setAllFilterDataAPI(JSON.parse(JSON.stringify(dataAPI)));

        setSelectedValues({
            ...nef
        });

    };

    const [filteredValues, setFilteredValues] = useState({});
    const [selectedValues, setSelectedValues] = useState({});
    const [values, setValues] = useState([]);

    const onChangeAddFilter = (type, value) => {
        // clients uuid

       // var valuesInner = values;
        var selected = JSON.parse(JSON.stringify(selectedValues));

        var selectedValuesArr = { ...selectedValues };
        var dataAPI = { ...allFilterDataAPI };
        var countCheckboxes = 0;

        var nef = {};


        var newValue = value;

        if (Array.isArray(newValue)) {

            newValue.forEach(element => {
                Object.keys(dataAPI).forEach(filterKey => {
                    if (filterKey === 'values') {
                        Object.keys(dataAPI[filterKey]).forEach(filterKeyValues => {
                            Object.keys(dataAPI[filterKey][filterKeyValues]).forEach(filterKeyValuesInside => {
                                if (filterKeyValues === type) {
                                    if (dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].uuid === element) {
                                        if (dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked) {
                                            function removeValueFromObject(obj, key, value) {
                                                const index = obj[key].indexOf(value);
                                                if (index !== -1) {
                                                    obj[key].splice(index, 1);
                                                }
                                                return obj;
                                            }
                                            const newObject = removeValueFromObject(selected, type, element);
                                            nef = newObject;
                                            dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked = false;
                                        } else {


                                            if(Array.isArray(selected[type])){
                                                selected[type].push( dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].uuid);
                                            } else {
                                                selected[type] = [ dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].uuid];
                                            }
                                            nef = selected;
                                            dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked = true;
                                        }
                                    }
                                }

                                if (dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked) {
                                    countCheckboxes++;
                                }
                            });
                        });
                    }
                });
            });

        } else {
            Object.keys(dataAPI).forEach(filterKey => {
                if (filterKey === 'values') {
                    Object.keys(dataAPI[filterKey]).forEach(filterKeyValues => {
                        Object.keys(dataAPI[filterKey][filterKeyValues]).forEach(filterKeyValuesInside => {
                            if (filterKeyValues === type) {
                                if (dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].uuid === newValue) {
                                    if (dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked) {
                                        function removeValueFromObject(obj, key, value) {
                                            const index = obj[key].indexOf(value);
                                            if (index !== -1) {
                                                obj[key].splice(index, 1);
                                            }
                                            return obj;
                                        }
                                        const newObject = removeValueFromObject(selected, type, newValue);
                                        nef = newObject;
                                        dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked = false;
                                    } else {

                                        if(Array.isArray(selected[type])){
                                            selected[type].push( dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].uuid);
                                        } else {
                                            selected[type] = [ dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].uuid];
                                        }
                                        nef = selected;
                                        dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked = true;
                                    }
                                }
                            }

                            if (dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked) {
                                countCheckboxes++;
                            }
                        });
                    });
                }
            });
        }





        if (null === value) {
            setSelectedValues({});

            Object.keys(dataAPI).forEach(filterKey => {
                if (filterKey === 'values') {
                    Object.keys(dataAPI[filterKey]).forEach(filterKeyValues => {
                        Object.keys(dataAPI[filterKey][filterKeyValues]).forEach(filterKeyValuesInside => {
                            dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked = false;
                        });
                    });
                }
            });

            setActiveFilterClients([]);
            setActiveFilterBrands([]);
            setActiveFilterEditions([]);
            setActiveFilterProductTypes([]);
            setActiveFilterSizes([]);

            simplifyAllValues();
            return;
        }

        if (countCheckboxes === 0) {
            setActiveFilterClients([]);
            setActiveFilterBrands([]);
            setActiveFilterEditions([]);
            setActiveFilterProductTypes([]);
            setActiveFilterSizes([]);
            setSelectedValues({});
            simplifyAllValues();
            return;
        }

        var newArray = { ...selected };
        newArray[type] = value;

            setAllFilterDataAPI(JSON.parse(JSON.stringify(dataAPI)));

        setSelectedValues({
            ...nef
        });

    };


    useEffect(() => {

        if (0 === Object.keys(selectedValues).length) {
            setFilteredValues(defaultFilteredValues);

            return;
        }

        const _filteredValues = {
            clients: [],
            brands: [],
            editions: [],
            product_types: [],
            volumes: [],
        };

        var newData = JSON.parse(JSON.stringify(allFilterDataAPI));

        newData.relations.forEach(rel => {
            Object.keys({ ...defaultFilteredValues }).forEach(filterKey => {
                const relKey = filterKeyToRelMappings[filterKey];

                if (rel[relKey] === undefined || rel[relKey] === null) {
                    return;
                }
                let candidate = true;

                Object.keys({ ...selectedValues }).forEach(selectedValueKey => {
                    if (selectedValueKey === filterKey) {
                        return;
                    }
                    if (check(selectedValues[selectedValueKey], `${rel[filterKeyToRelMappings[selectedValueKey]]}`)) {
                        return;
                    }

                    candidate = false;
                });

                if (candidate && -1 === _filteredValues[filterKey].indexOf(`${rel[relKey]}`)) {

                    _filteredValues[filterKey].push(`${rel[relKey]}`);
                }
            });
        });

      //  console.log("SET FILTERED VALUES ", _filteredValues);
       // console.log("SET selectedValues ", selectedValues);

        setFilteredValues(JSON.parse(JSON.stringify(_filteredValues)));
    }, [selectedValues]);

    const filterKeyToRelMappings = {
        clients: 'client',
        brands: 'brand',
        editions: 'edition',
        product_types: 'product_type',
        volumes: 'volume',
    };

    const check = (_selectedValues, _rel) => {
        const isMultiple = Array.isArray(_selectedValues);

        return isMultiple ? -1 !== _selectedValues.indexOf(_rel) : _selectedValues === _rel;
    };

    const [filterRelations, setFilterRelations] = useState([]);

    const [originalSelectedValues, setOriginalSelectedValues] = useState({});

    const loadProductsFiltersAPI = () => {
        console.log("LOADING PROUDCT FILTER");
        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'filter/products', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                Authorization: 'Bearer ' + getCookie('userTeam'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function (data) {
                if (data.message !== undefined) {
                    Sentry.captureException('edit filter/products' + ': ' + data.message + ' : ' + getCookie('userMail'));
                }

                setFilterClientsProducts(data.values.clients);
                setFilterBrandsProducts(data.values.brands);
                setFilterEditionsProducts(data.values.editions);

                setFilterClients(data.values.clients);
                setFilterBrands(data.values.brands);
                setFilterEditions(data.values.editions);
                setFilterProductTypes(data.values.product_types);
                setFilterSizes(data.values.volumes);
                setFilterRelations(data.relations);
                const dataAPI = data;



                 //Create empty relations for values without any relation
                const newRelations = [];
                const uuidExistsInRelations = (uuid, key) => {
                    return dataAPI.relations.some(relation => relation[key] === uuid);
                };

                for (const key in dataAPI.values) {
                    if (dataAPI.values.hasOwnProperty(key)) {
                        const items = dataAPI.values[key];
                        for (const item of items) {
                            const itemUuid = item.uuid;
                            const relationKey = key.slice(0, -1);
                            if (!uuidExistsInRelations(itemUuid, relationKey)) {
                                const newRelation = {
                                    client: null,
                                    brand: null,
                                    edition: null,
                                    product_type: null,
                                    volume: null,
                                };

                                newRelation[relationKey] = itemUuid;
                                newRelations.push(newRelation);
                            }
                        }
                    }
                }
                dataAPI.relations.push(...newRelations);
                //End of creating empty relations


                Object.keys(dataAPI).forEach(filterKey => {
                    if (filterKey === 'values') {
                        Object.keys(dataAPI[filterKey]).forEach(filterKeyValues => {
                            Object.keys(dataAPI[filterKey][filterKeyValues]).forEach(filterKeyValuesInside => {
                                if (typeof dataAPI[filterKey][filterKeyValues][filterKeyValuesInside] === 'string') {
                                    // 👇️ this runs
                                    dataAPI[filterKey][filterKeyValues][filterKeyValuesInside] = {
                                        uuid: dataAPI[filterKey][filterKeyValues][filterKeyValuesInside],
                                        name: dataAPI[filterKey][filterKeyValues][filterKeyValuesInside],
                                        checked: false,
                                    };
                                } else {
                                    dataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked = false;
                                }
                            });
                        });
                    }
                });

                setAllFilterDataAPI(JSON.parse(JSON.stringify(dataAPI)));

            });
    };

    const [isSimplified, setIsSimplified] = useState(false);

    const [isInitedOnLoad, setIsInitedOnLoad] = useState(false);

    useEffect(() => {
        //   setLoadProductsShow(true);

        if (allFilterDataAPI.values) {
            simplifyAllValues();



            if (Object.keys(router.query).length > 0 && isSimplified && loadinProductsComplete && !isInitedOnLoad) {

                var nef2 = {};
                setSelectedValues(nef2);


                if (router.query.clients) {
                    nef2["clients"] = router.query.clients.split(',');

              //    onChangeAddFilter('clients', router.query.clients.split(','));
                    //showFilteredProducts({ clients: router.query.clients.split(',') });
                }
                if (router.query.brands) {
                 //  onChangeAddFilter('brands', router.query.brands.split(','));
               //     showFilteredProducts({ brands: router.query.brands.split(',') });
                    nef2["brands"] = router.query.brands.split(',');
                }
                if (router.query.editions) {
                    nef2["editions"] = router.query.editions.split(',');
                  //  onChangeAddFilter('editions', router.query.editions.split(','));
                    //showFilteredProducts({ editions: router.query.editions.split(',') });
                }
                if (router.query.product_types) {
                    nef2["product_types"] = router.query.product_types.split(',');
                   // onChangeAddFilter('product_types', router.query.product_types.split(','));
                    //showFilteredProducts({ product_types: router.query.product_types.split(',') });
                }
                if (router.query.volumes) {
                    nef2["volumes"] = router.query.volumes.split(',');
                  // onChangeAddFilter('volumes', router.query.volumes.split(','));
               //     showFilteredProducts({ volumes: router.query.volumes.split(',') });
                }

                onLoadAddFilter(nef2);


                setSelectedValues(nef2);

                showFilteredProducts(nef2);
                setIsInitedOnLoad(true);
            }
        } else {
            //  setLoadProductsShow(false);
        }
    }, [allFilterDataAPI, isSimplified, loadinProductsComplete]);

    const simplifyAllValues = () => {
        if (!filterActive) {
            const values = {};
            Object.keys(allFilterDataAPI.values).forEach(key => {
                values[key] = allFilterDataAPI.values[key].map(val => {
                    return typeof val === 'object' ? val.uuid : val;
                });
            });

            setFilteredValues(values);
            setDefaultFilteredValues(values);

            setIsSimplified(true);
        }
    };

    useEffect(() => {
        var newClientTags = [];
        var newBrandTags = [];
        var newEditionTags = [];
        var newProductTypes = [];
        var newSizes = [];

        Object.keys(originalDataAPI).forEach(filterKey => {
            if (filterKey === 'values') {
                Object.keys(originalDataAPI[filterKey]).forEach(filterKeyValues => {
                    Object.keys(originalDataAPI[filterKey][filterKeyValues]).forEach(filterKeyValuesInside => {
                        if (originalDataAPI[filterKey][filterKeyValues][filterKeyValuesInside].checked) {
                            if (filterKeyValues === 'clients') {
                                newClientTags.push(originalDataAPI[filterKey][filterKeyValues][filterKeyValuesInside].uuid);
                            }
                            if (filterKeyValues === 'brands') {
                                newBrandTags.push(originalDataAPI[filterKey][filterKeyValues][filterKeyValuesInside].uuid);
                            }
                            if (filterKeyValues === 'editions') {
                                newEditionTags.push(originalDataAPI[filterKey][filterKeyValues][filterKeyValuesInside].uuid);
                            }
                            if (filterKeyValues === 'product_types') {
                                newProductTypes.push(originalDataAPI[filterKey][filterKeyValues][filterKeyValuesInside].uuid);
                            }
                            if (filterKeyValues === 'volumes') {
                                newSizes.push(originalDataAPI[filterKey][filterKeyValues][filterKeyValuesInside].uuid);
                            }
                        }
                    });
                });
            }
        });

        setActiveFilterClients(newClientTags);
        setActiveFilterBrands(newBrandTags);
        setActiveFilterEditions(newEditionTags);
        setActiveFilterProductTypes(newProductTypes);
        setActiveFilterSizes(newSizes);

    }, [originalDataAPI]);

    const [appExternal, setAppExternal] = useState(false);

    const loadUserInfoAPI = () => {
        var tokenMain = getCookie('userTeam');

        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'auth/info', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                Authorization: 'Bearer ' + tokenMain, // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function (data) {

                if (data.status === 401) {
                } else {
                    if (!getCookie('userMail') && getCookie('userMail') !== data.user.email) {
                        setCookie('userMail', data.user.email, { maxAge: 10 * 365 * 24 * 60 * 60 });
                    }

                    Object.keys(data.teams).map(key => {
                        if (getCookie('userTeamUUID') === data.teams[key]['uuid']) {
                            if (data.teams[key]['role'] === 'external') {
                                setAppExternal(true);
                            }
                        }
                    });
                }
                if (data.message !== undefined) {
                    Sentry.captureException('auth/info' + ': ' + data.message + ' : ' + getCookie('userMail'));
                }
            });
    };

    const [isInited, setInited] = useState(false);

    useEffect(() => {
        if (!isInited) {
            if(window.innerWidth<600){
                setIsOpener(true);

            }
            if(disabledFilters){
                ReactTooltip.rebuild();
            }

            loadUserInfoAPI();
            loadProductsFiltersAPI();
            setInited(true);
        }
    }, [isInited]);

    const closeModalOutsideClick = e => {
        if (e.target.getAttribute('data-closemodal') === 'true') {
            changeActiveFilterSide(false);

            setAllFilterDataAPI(JSON.parse(JSON.stringify(originalDataAPI)));
            setSelectedValues({ ...originalSelectedValues });
        }
    };

    const closeModalOutsideClickEdit = e => {
        if (e.target.getAttribute('data-closemodal') === 'true') {
            setModalShow(false, 'editAll');
        }
    };

    const [warningMessage, setWarningMessage] = useState('');
    let triggerWarning = warningMessage => {
        // changeLoadingShowTop(false);
        setWarningMessage(warningMessage);
        handleTriggerWarning();
    };

    let handleTriggerWarning = () => {};

    let handleClickDelete = () => {};

    return (
        <>
            {!isOpener ?
                <StyledProductFilter>
                    <div className={disabledFilters ? 'filterButtons disabledFilters' : 'filterButtons'}

                         data-tip={disabledFilters ? "Filters are available from the basic plan" : null}
                         data-place="bottom"
                         data-delay-show="200"
                         data-closemodalmenu={true}>
                        <div
                            data-closemodalmenu={true}
                            className={`filterButton ${filterClientsOpened ? 'active' : ''}  ${activeFilterClients.length > 0 ? 'activated' : null} `}
                        >
                            <div className={'filterName'}>
                            <span id={'clientFilterButton'} onClick={!disabledFilters ? () => openFilter('clients') : null}>
                                <FormattedMessage {...messages.products_filter_clients}></FormattedMessage>
                            </span>
                            </div>

                            {activeFilterClients.length > 0 ? (
                                <div className={'filterTags'} data-closemodalmenu={true}>
                                    {allFilterDataAPI.values &&
                                        allFilterDataAPI.values.clients.map((value, index) => {
                                            if (activeFilterClients.includes(value.uuid)) {
                                                return (
                                                    <div className={'filterTag'}>
                                                        <span>{value.name}</span>
                                                        <span onClick={() => removeFilterGroup(value.uuid, 'clients')} className={'close'}>
                                                        &nbsp;
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 384 512"
                                                                onClick={() => changeActiveFilterSide(false)}
                                                            >
                                                            <path d="M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z" />
                                                        </svg>
                                                    </span>
                                                    </div>
                                                );
                                            }
                                        })}
                                </div>
                            ) : null}
                        </div>

                        <div
                            data-closemodalmenu={true}
                            className={`filterButton ${filterBrandsOpened ? 'active' : ''}  ${activeFilterBrands.length > 0 ? 'activated' : null} `}
                        >
                            <div className={'filterName'}>
                            <span onClick={!disabledFilters ? () => openFilter('brands') : null}>
                                {' '}
                                <FormattedMessage {...messages.products_filter_brands}></FormattedMessage>
                            </span>
                            </div>

                            {activeFilterBrands.length > 0 ? (
                                <div className={'filterTags'}>
                                    {filterBrands.map((value, index) => {
                                        if (activeFilterBrands.includes(value.uuid)) {
                                            return (
                                                <div className={'filterTag'}>
                                                    <span> {value.name}</span>
                                                    <span onClick={() => removeFilterGroup(value.uuid, 'brands')} className={'close'}>
                                                    &nbsp;
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 384 512"
                                                            onClick={() => changeActiveFilterSide(false)}
                                                        >
                                                        <path d="M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z" />
                                                    </svg>
                                                </span>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            ) : null}
                        </div>

                        <div
                            data-closemodalmenu={true}
                            className={`filterButton ${filterEditionsOpened ? 'active' : ''}  ${activeFilterEditions.length > 0 ? 'activated' : null} `}
                        >
                            <div className={'filterName'}>
                            <span onClick={!disabledFilters ? () => openFilter('editions') : null}>
                                <FormattedMessage {...messages.products_filter_editions}></FormattedMessage>
                            </span>
                            </div>

                            {activeFilterEditions.length > 0 ? (
                                <div className={'filterTags'}>
                                    {filterEditions.map((value, index) => {
                                        if (activeFilterEditions.includes(value.uuid)) {
                                            return (
                                                <div className={'filterTag'}>
                                                    <span> {value.name}</span>
                                                    <span onClick={() => removeFilterGroup(value.uuid, 'editions')} className={'close'}>
                                                    &nbsp;
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 384 512"
                                                            onClick={() => changeActiveFilterSide(false)}
                                                        >
                                                        <path d="M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z" />
                                                    </svg>
                                                </span>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            ) : null}
                        </div>

                        {type !== 'assignProduct' ? (
                            <>
                                <div
                                    data-closemodalmenu={true}
                                    className={`filterButton ${filterProductTypesOpened ? 'active' : ''}  ${
                                        activeFilterProductTypes.length > 0 ? 'activated' : null
                                    } `}
                                >
                                    <div className={'filterName'}>
                                    <span onClick={!disabledFilters ? () => openFilter('productTypes') : null}>
                                        <FormattedMessage {...messages.products_filter_producttypes}></FormattedMessage>
                                    </span>
                                    </div>

                                    {activeFilterProductTypes.length > 0 ? (
                                        <div className={'filterTags'}>
                                            {filterProductTypes.map((value, index) => {
                                                if (activeFilterProductTypes.includes(value.uuid)) {
                                                    return (
                                                        <div className={'filterTag'}>
                                                            <span>{value.name}</span>
                                                            <span onClick={() => removeFilterGroup(value.uuid, 'product_types')} className={'close'}>
                                                            &nbsp;
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 384 512"
                                                                    onClick={() => changeActiveFilterSide(false)}
                                                                >
                                                                <path d="M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z" />
                                                            </svg>
                                                        </span>
                                                        </div>
                                                    );
                                                }
                                            })}
                                        </div>
                                    ) : null}
                                </div>

                                <div
                                    data-closemodalmenu={true}
                                    className={`filterButton ${filterSizesOpened ? 'active' : ''}  ${activeFilterSizes.length > 0 ? 'activated' : null} `}
                                >
                                    <div className={'filterName'}>
                                    <span onClick={!disabledFilters ? () => openFilter('sizes') : null}>
                                        <FormattedMessage {...messages.products_filter_sizes}></FormattedMessage>
                                    </span>
                                    </div>

                                    {activeFilterSizes.length > 0 ? (
                                        <div className={'filterTags'}>
                                            {filterSizes.map((value, index) => {
                                                if (activeFilterSizes.includes(value.uuid)) {
                                                    return (
                                                        <div className={'filterTag'}>
                                                            <span> {value.name}</span>
                                                            <span onClick={() => removeFilterGroup(value.uuid, 'volumes')} className={'close'}>
                                                            &nbsp;
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 384 512"
                                                                    onClick={() => changeActiveFilterSide(false)}
                                                                >
                                                                <path d="M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z" />
                                                            </svg>
                                                        </span>
                                                        </div>
                                                    );
                                                }
                                            })}
                                        </div>
                                    ) : null}
                                </div>
                            </>
                        ) : null}
                    </div>

                </StyledProductFilter>
            : <div>
                    {isOpen ? <>
                            <StyledProductFilter>
                                <div className={'filterButtons'} data-closemodalmenu={true}>
                                    <div
                                        data-closemodalmenu={true}
                                        className={`filterButton ${filterClientsOpened ? 'active' : ''}  ${activeFilterClients.length > 0 ? 'activated' : null} `}
                                    >
                                        <div className={'filterName'}>
                            <span id={'clientFilterButton'} onClick={() => openFilter('clients')}>
                                <FormattedMessage {...messages.products_filter_clients}></FormattedMessage>
                            </span>
                                        </div>

                                        {activeFilterClients.length > 0 ? (
                                            <div className={'filterTags'} data-closemodalmenu={true}>
                                                {allFilterDataAPI.values &&
                                                    allFilterDataAPI.values.clients.map((value, index) => {
                                                        if (activeFilterClients.includes(value.uuid)) {
                                                            return (
                                                                <div className={'filterTag'}>
                                                                    <span>{value.name}</span>
                                                                    <span
                                                                        onClick={() => removeFilterGroup(value.uuid, 'clients')}
                                                                        className={'close'}>
                                                        &nbsp;
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 384 512"
                                                                            onClick={() => changeActiveFilterSide(false)}
                                                                        >
                                                            <path
                                                                d="M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z" />
                                                        </svg>
                                                    </span>
                                                                </div>
                                                            );
                                                        }
                                                    })}
                                            </div>
                                        ) : null}
                                    </div>

                                    <div
                                        data-closemodalmenu={true}
                                        className={`filterButton ${filterBrandsOpened ? 'active' : ''}  ${activeFilterBrands.length > 0 ? 'activated' : null} `}
                                    >
                                        <div className={'filterName'}>
                            <span onClick={() => openFilter('brands')}>
                                {' '}
                                <FormattedMessage {...messages.products_filter_brands}></FormattedMessage>
                            </span>
                                        </div>

                                        {activeFilterBrands.length > 0 ? (
                                            <div className={'filterTags'}>
                                                {filterBrands.map((value, index) => {
                                                    if (activeFilterBrands.includes(value.uuid)) {
                                                        return (
                                                            <div className={'filterTag'}>
                                                                <span> {value.name}</span>
                                                                <span
                                                                    onClick={() => removeFilterGroup(value.uuid, 'brands')}
                                                                    className={'close'}>
                                                    &nbsp;
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 384 512"
                                                                        onClick={() => changeActiveFilterSide(false)}
                                                                    >
                                                        <path
                                                            d="M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z" />
                                                    </svg>
                                                </span>
                                                            </div>
                                                        );
                                                    }
                                                })}
                                            </div>
                                        ) : null}
                                    </div>

                                    <div
                                        data-closemodalmenu={true}
                                        className={`filterButton ${filterEditionsOpened ? 'active' : ''}  ${activeFilterEditions.length > 0 ? 'activated' : null} `}
                                    >
                                        <div className={'filterName'}>
                            <span onClick={() => openFilter('editions')}>
                                <FormattedMessage {...messages.products_filter_editions}></FormattedMessage>
                            </span>
                                        </div>

                                        {activeFilterEditions.length > 0 ? (
                                            <div className={'filterTags'}>
                                                {filterEditions.map((value, index) => {
                                                    if (activeFilterEditions.includes(value.uuid)) {
                                                        return (
                                                            <div className={'filterTag'}>
                                                                <span> {value.name}</span>
                                                                <span
                                                                    onClick={() => removeFilterGroup(value.uuid, 'editions')}
                                                                    className={'close'}>
                                                    &nbsp;
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 384 512"
                                                                        onClick={() => changeActiveFilterSide(false)}
                                                                    >
                                                        <path
                                                            d="M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z" />
                                                    </svg>
                                                </span>
                                                            </div>
                                                        );
                                                    }
                                                })}
                                            </div>
                                        ) : null}
                                    </div>

                                    {type !== 'assignProduct' ? (
                                        <>
                                            <div
                                                data-closemodalmenu={true}
                                                className={`filterButton ${filterProductTypesOpened ? 'active' : ''}  ${
                                                    activeFilterProductTypes.length > 0 ? 'activated' : null
                                                } `}
                                            >
                                                <div className={'filterName'}>
                                    <span onClick={() => openFilter('productTypes')}>
                                        <FormattedMessage {...messages.products_filter_producttypes}></FormattedMessage>
                                    </span>
                                                </div>

                                                {activeFilterProductTypes.length > 0 ? (
                                                    <div className={'filterTags'}>
                                                        {filterProductTypes.map((value, index) => {
                                                            if (activeFilterProductTypes.includes(value.uuid)) {
                                                                return (
                                                                    <div className={'filterTag'}>
                                                                        <span>{value.name}</span>
                                                                        <span
                                                                            onClick={() => removeFilterGroup(value.uuid, 'product_types')}
                                                                            className={'close'}>
                                                            &nbsp;
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                viewBox="0 0 384 512"
                                                                                onClick={() => changeActiveFilterSide(false)}
                                                                            >
                                                                <path
                                                                    d="M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z" />
                                                            </svg>
                                                        </span>
                                                                    </div>
                                                                );
                                                            }
                                                        })}
                                                    </div>
                                                ) : null}
                                            </div>

                                            <div
                                                data-closemodalmenu={true}
                                                className={`filterButton ${filterSizesOpened ? 'active' : ''}  ${activeFilterSizes.length > 0 ? 'activated' : null} `}
                                            >
                                                <div className={'filterName'}>
                                    <span onClick={() => openFilter('sizes')}>
                                        <FormattedMessage {...messages.products_filter_sizes}></FormattedMessage>
                                    </span>
                                                </div>

                                                {activeFilterSizes.length > 0 ? (
                                                    <div className={'filterTags'}>
                                                        {filterSizes.map((value, index) => {
                                                            if (activeFilterSizes.includes(value.uuid)) {
                                                                return (
                                                                    <div className={'filterTag'}>
                                                                        <span> {value.name}</span>
                                                                        <span
                                                                            onClick={() => removeFilterGroup(value.uuid, 'volumes')}
                                                                            className={'close'}>
                                                            &nbsp;
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                viewBox="0 0 384 512"
                                                                                onClick={() => changeActiveFilterSide(false)}
                                                                            >
                                                                <path
                                                                    d="M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z" />
                                                            </svg>
                                                        </span>
                                                                    </div>
                                                                );
                                                            }
                                                        })}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            </StyledProductFilter>
                            <span style={{marginTop:"15px", display:"block"}} onClick={()=> setIsOpen(false)} >
                                  <FontAwesomeIcon icon={faChevronUp} style={{ marginRight: '5px' }} />

                                <FormattedMessage {...messages.products_filter_close_filter}></FormattedMessage></span>
                        </>

                        : <span onClick={()=> setIsOpen(true)}>
                                <FontAwesomeIcon icon={faChevronDown} style={{ marginRight: '5px' }} />
                            <FormattedMessage {...messages.products_filter_open_filter}></FormattedMessage></span>
                    }

                </div>
            }


            <DialogGroupsModalEditAdd
                modalTransitionShow={modalTransitionShow}
                editAllModalActive={editAllModalActive}
                editionsModalActive={editionsModalActive}
                clientModalActive={clientModalActive}
                brandModalActive={brandModalActive}
                editAllName={editAllName}
                editAllTypeHeaderText={editAllTypeHeaderText}
                editAllType={editAllType}
                editAllIcon={editAllIcon}
                editAllUUID={editAllUUID}
                parentClient={parentClient}
                parentBrand={parentBrand}
                triggerWarning={triggerWarning}
                setModalShow={setModalShow}
                changeEditAllName={changeEditAllName}
                filterClients={filterClients}
                loadProductsFilters={loadProductsFiltersAPI}
                filterBrands={filterBrands}
                closeModalOutsideClickEdit={closeModalOutsideClickEdit}
            />

            <ModalsWrapper
                className={` ${modalTransitionShowActiveFilterSide ? 'showTransition' : 'hideTransition'} ${
                    activeFilterSide ? '' + '' : 'hiddeComplete'
                } `}
                style={{ zIndex: '999999999', overflow: 'hidden' }}
                data-closemodal={true}
                onClick={e => closeModalOutsideClick(e)}
            >
                <ModalWindowRightSide className={`deleteCheck center  ${activeFilterSide ? '' : 'hideModal'}`}>
                    <div>
                        <div className={'modal-header'}>
                            <div className="MuiBox-root-7 jss1517 jss359">
                                <h3
                                    style={{ marginBottom: '15px!important' }}
                                    className="MuiTypography-root-106 MuiTypography-h3-113 MuiTypography-colorPrimary-129"
                                >
                                    {openedFilterName === 'Clients' ? (
                                        <FormattedMessage {...messages.products_filter_clients}></FormattedMessage>
                                    ) : null}
                                    {openedFilterName === 'Brands' ? (
                                        <FormattedMessage {...messages.products_filter_brands}></FormattedMessage>
                                    ) : null}
                                    {openedFilterName === 'Editions' ? (
                                        <FormattedMessage {...messages.products_filter_editions}></FormattedMessage>
                                    ) : null}
                                    {openedFilterName === 'Product types' ? (
                                        <FormattedMessage {...messages.products_filter_producttypes}></FormattedMessage>
                                    ) : null}
                                    {openedFilterName === 'Sizes' ? <FormattedMessage {...messages.products_filter_sizes}></FormattedMessage> : null}
                                </h3>

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" onClick={() => changeActiveFilterSide(false)}>
                                    <path d="M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z" />
                                </svg>
                            </div>
                        </div>

                        {filterClientsOpened && !appExternal ? (
                            <GlobalButtonFinal
                                onClick={() => setModalShow(true, 'client')}
                                id={'addNewClientBtn'}
                                style={{
                                    width: 'fit-content',
                                    fontSize: '15px',
                                    marginBottom: '30px',
                                }}
                                className={'borderLightGray btn-circle'}
                            >
                                <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} />{' '}
                                <FormattedMessage {...messages.products_filter_modal_groups_client_add_title}></FormattedMessage>
                            </GlobalButtonFinal>
                        ) : null}
                        {filterBrandsOpened && !appExternal ? (
                            <GlobalButtonFinal
                                onClick={() => setModalShow(true, 'brand')}
                                style={{ width: 'fit-content', fontSize: '15px', marginBottom: '30px' }}
                                className={'borderLightGray btn-circle'}
                            >
                                <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} />
                                <FormattedMessage {...messages.products_filter_modal_groups_brand_add_title}></FormattedMessage>
                            </GlobalButtonFinal>
                        ) : null}
                        {filterEditionsOpened && !appExternal ? (
                            <GlobalButtonFinal
                                onClick={() => setModalShow(true, 'edition')}
                                style={{ width: 'fit-content', fontSize: '15px', marginBottom: '30px' }}
                                className={'borderLightGray btn-circle'}
                            >
                                <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} />
                                <FormattedMessage {...messages.products_filter_modal_groups_edition_add_title}></FormattedMessage>
                            </GlobalButtonFinal>
                        ) : null}

                        <div className={'modal-content '} id={'groupsContent'} style={{ display: 'block', width: '100%' }}>
                            {filterClientsOpened ? (
                                <>
                                    <ul>
                                        {allFilterDataAPI.values && allFilterDataAPI.values.clients !== undefined && filterClients.length > 0 ? (
                                            <>
                                                {allFilterDataAPI.values.clients.map((value, index) => {
                                                    return (
                                                        <li
                                                            key={'filter-clients-' + index}
                                                            className={
                                                                filteredValues.clients && filteredValues.clients.includes(value.uuid)
                                                                    ? ''
                                                                    : 'disabledFilter'
                                                            }
                                                        >
                                                            <div className={'logoInput'}>
                                                                <input
                                                                    className="jss1595"
                                                                    tabIndex="-1"
                                                                    type="checkbox"
                                                                    data-indeterminate="false"
                                                                    checked={value.checked}
                                                                    disabled={
                                                                        filterActive
                                                                            ? filteredValues.clients.includes(value.uuid)
                                                                                ? false
                                                                                : true
                                                                            : false
                                                                    }
                                                                    onClick={() => onChangeAddFilter('clients', value.uuid)}
                                                                    // onClick={ e => chooseClientFilterFunction(e, value.uuid, value.name) }
                                                                />
                                                            </div>
                                                            <div className={'content'} onClick={() => onChangeAddFilter('clients', value.uuid)}>
                                                                <div className={'logoInside'}>
                                                                    {value.logo ? (
                                                                        <img src={value.logo} />
                                                                    ) : (
                                                                        <FontAwesomeIcon className="newIcon" icon={faMugHot} />
                                                                    )}
                                                                </div>

                                                                <p>{value.name}</p>
                                                            </div>

                                                            {!appExternal ? (
                                                                <div className={'content-buttons'}>
                                                                    <button
                                                                        className={`optionsFilterItem`}
                                                                        onClick={() =>
                                                                            startEditAnything('client', value.uuid, value.name, value.logo, null)
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon icon={faPen} />
                                                                    </button>
                                                                    <button className={`optionsFilterItem`} onClick={() => showDelete(value.uuid)}>
                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                    </button>
                                                                </div>
                                                            ) : null}
                                                        </li>
                                                    );
                                                })}
                                                <div className={'modal-buttons'} style={{ display: 'block', width: '100%' }}>
                                                    <GlobalButtonFinal className={'btn-circle backgroundBlue'} onClick={() => showFilteredProducts()}>
                                                        <FormattedMessage
                                                            {...messages.products_filter_modal_groups_button_show_results}
                                                        ></FormattedMessage>
                                                    </GlobalButtonFinal>
                                                    <GlobalButtonFinal
                                                        onClick={() => onChangeAddFilter('clients', null)}
                                                        className={'btn-circle borderLightGray'}
                                                        style={{ marginLeft: '5px' }}
                                                    >
                                                        <FormattedMessage
                                                            {...messages.products_filter_modal_groups_button_clear_filter}
                                                        ></FormattedMessage>
                                                    </GlobalButtonFinal>
                                                </div>
                                            </>
                                        ) : null}
                                    </ul>
                                </>
                            ) : null}
                            {filterBrandsOpened ? (
                                <ul>
                                    {allFilterDataAPI.values &&
                                    allFilterDataAPI.values.brands !== undefined &&
                                    allFilterDataAPI.values.brands.length > 0 ? (
                                        <>
                                            {allFilterDataAPI.values.brands.map((value, index) => {

                                                return (
                                                    <li
                                                        key={'filter-brand-' + index}
                                                        className={
                                                            filteredValues.brands && filteredValues.brands.includes(value.uuid)
                                                                ? ''
                                                                : 'disabledFilter'
                                                        }
                                                    >
                                                        <div className={'logoInput'}>
                                                            <input
                                                                className="jss1595"
                                                                tabIndex="-1"
                                                                type="checkbox"
                                                                data-indeterminate="false"
                                                                checked={value.checked}
                                                                disabled={
                                                                    filterActive ? (filteredValues.brands.includes(value.uuid) ? false : true) : false
                                                                }
                                                                onClick={() => onChangeAddFilter('brands', value.uuid)}
                                                            />
                                                        </div>
                                                        <div className={'content'} onClick={() => onChangeAddFilter('brands', value.uuid)}>
                                                            <div className={'logoInside'}>
                                                                {value.logo ? (
                                                                    <img src={value.logo} />
                                                                ) : (
                                                                    <FontAwesomeIcon className="newIcon" icon={faMugHot} />
                                                                )}
                                                            </div>

                                                            <p>{value.name}</p>
                                                        </div>

                                                        {!appExternal ? (
                                                            <div className={'content-buttons'}>
                                                                <button
                                                                    className={`optionsFilterItem`}
                                                                    onClick={() => startEditAnything('brand', value.uuid, value.name, value.logo, value.client_uuid)}
                                                                >
                                                                    <FontAwesomeIcon icon={faPen} />
                                                                </button>
                                                                <button className={`optionsFilterItem`} onClick={() => showDelete(value.uuid)}>
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </button>
                                                            </div>
                                                        ) : null}
                                                    </li>
                                                );
                                            })}
                                            <div className={'modal-buttons'} style={{ display: 'block', width: '100%' }}>
                                                <GlobalButtonFinal className={'btn-circle backgroundBlue'} onClick={() => showFilteredProducts()}>
                                                    <FormattedMessage
                                                        {...messages.products_filter_modal_groups_button_show_results}
                                                    ></FormattedMessage>
                                                </GlobalButtonFinal>
                                                <GlobalButtonFinal
                                                    className={'btn-circle borderLightGray'}
                                                    onClick={() => onChangeAddFilter('clients', null)}
                                                    style={{ marginLeft: '5px' }}
                                                >
                                                    <FormattedMessage
                                                        {...messages.products_filter_modal_groups_button_clear_filter}
                                                    ></FormattedMessage>
                                                </GlobalButtonFinal>
                                            </div>
                                        </>
                                    ) : null}
                                </ul>
                            ) : null}
                            {filterEditionsOpened ? (
                                <ul>
                                    {allFilterDataAPI.values &&
                                    allFilterDataAPI.values.editions !== undefined &&
                                    allFilterDataAPI.values.editions.length > 0 ? (
                                        <>
                                            {allFilterDataAPI.values.editions.map((value, index) => {
                                                return (
                                                    <li
                                                        key={'filter-editions-' + index}
                                                        className={
                                                            filteredValues.editions && filteredValues.editions.includes(value.uuid)
                                                                ? ''
                                                                : 'disabledFilter'
                                                        }
                                                    >
                                                        <div className={'logoInput'}>
                                                            <input
                                                                className="jss1595"
                                                                tabIndex="-1"
                                                                type="checkbox"
                                                                data-indeterminate="false"
                                                                checked={value.checked}
                                                                disabled={
                                                                    filterActive
                                                                        ? filteredValues.editions.includes(value.uuid)
                                                                            ? false
                                                                            : true
                                                                        : false
                                                                }
                                                                onClick={() => onChangeAddFilter('editions', value.uuid)}
                                                            />
                                                        </div>
                                                        <div className={'content'} onClick={() => onChangeAddFilter('editions', value.uuid)}>
                                                            <div className={'logoInside'}>
                                                                {value.logo ? (
                                                                    <img src={value.logo} />
                                                                ) : (
                                                                    <FontAwesomeIcon className="newIcon" icon={faMugHot} />
                                                                )}
                                                            </div>
                                                            <p>{value.name}</p>
                                                        </div>

                                                        {!appExternal ? (
                                                            <div className={'content-buttons'}>
                                                                <button
                                                                    className={`optionsFilterItem`}
                                                                    onClick={() => startEditAnything('edition', value.uuid, value.name, value.logo, value.brand_uuid)}
                                                                >
                                                                    <FontAwesomeIcon icon={faPen} />
                                                                </button>
                                                                <button className={`optionsFilterItem`} onClick={() => showDelete(value.uuid)}>
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </button>
                                                            </div>
                                                        ) : null}
                                                    </li>
                                                );
                                            })}
                                            <div className={'modal-buttons'} style={{ display: 'block', width: '100%' }}>
                                                <GlobalButtonFinal className={'btn-circle backgroundBlue'} onClick={() => showFilteredProducts()}>
                                                    <FormattedMessage
                                                        {...messages.products_filter_modal_groups_button_show_results}
                                                    ></FormattedMessage>
                                                </GlobalButtonFinal>
                                                <GlobalButtonFinal
                                                    className={'btn-circle borderLightGray'}
                                                    onClick={() => onChangeAddFilter('clients', null)}
                                                    style={{ marginLeft: '5px' }}
                                                >
                                                    <FormattedMessage
                                                        {...messages.products_filter_modal_groups_button_clear_filter}
                                                    ></FormattedMessage>
                                                </GlobalButtonFinal>
                                            </div>
                                        </>
                                    ) : null}
                                </ul>
                            ) : null}
                            {filterProductTypesOpened ? (
                                <ul>
                                    {allFilterDataAPI.values &&
                                    allFilterDataAPI.values.product_types !== undefined &&
                                    allFilterDataAPI.values.product_types.length > 0 ? (
                                        <>
                                            {allFilterDataAPI.values.product_types.map((value, index) => {
                                                return (
                                                    <li
                                                        key={'filter-productType-' + index}
                                                        className={
                                                            filteredValues.product_types && filteredValues.product_types.includes(value.uuid)
                                                                ? ''
                                                                : 'disabledFilter'
                                                        }
                                                    >
                                                        <div className={'logoInput'}>
                                                            <input
                                                                className="jss1595"
                                                                tabIndex="-1"
                                                                type="checkbox"
                                                                data-indeterminate="false"
                                                                checked={value.checked}
                                                                disabled={
                                                                    filterActive
                                                                        ? filteredValues.product_types.includes(value.uuid)
                                                                            ? false
                                                                            : true
                                                                        : false
                                                                }
                                                                onClick={() => onChangeAddFilter('product_types', value.uuid)}
                                                            />
                                                        </div>
                                                        <div className={'content'} onClick={() => onChangeAddFilter('product_types', value.uuid)}>
                                                            <p>{value.name}</p>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                            <div className={'modal-buttons'} style={{ display: 'block', width: '100%' }}>
                                                <GlobalButtonFinal className={'btn-circle backgroundBlue'} onClick={() => showFilteredProducts()}>
                                                    <FormattedMessage
                                                        {...messages.products_filter_modal_groups_button_show_results}
                                                    ></FormattedMessage>
                                                </GlobalButtonFinal>
                                                <GlobalButtonFinal
                                                    className={'btn-circle borderLightGray'}
                                                    onClick={() => onChangeAddFilter('clients', null)}
                                                    style={{ marginLeft: '5px' }}
                                                >
                                                    <FormattedMessage
                                                        {...messages.products_filter_modal_groups_button_clear_filter}
                                                    ></FormattedMessage>
                                                </GlobalButtonFinal>
                                            </div>
                                        </>
                                    ) : null}
                                </ul>
                            ) : null}
                            {filterSizesOpened ? (
                                <ul>
                                    {allFilterDataAPI.values &&
                                    allFilterDataAPI.values.volumes !== undefined &&
                                    allFilterDataAPI.values.volumes.length > 0 ? (
                                        <>
                                            {allFilterDataAPI.values.volumes.map((value, index) => {
                                                return (
                                                    <li
                                                        key={'filter-size-' + index}
                                                        className={
                                                            filteredValues.volumes && filteredValues.volumes.includes(value.uuid)
                                                                ? ''
                                                                : 'disabledFilter'
                                                        }
                                                    >
                                                        <div className={'logoInput'}>
                                                            <input
                                                                className="jss1595"
                                                                tabIndex="-1"
                                                                type="checkbox"
                                                                data-indeterminate="false"
                                                                checked={value.checked}
                                                                disabled={
                                                                    filterActive
                                                                        ? filteredValues.volumes.includes(value.uuid)
                                                                            ? false
                                                                            : true
                                                                        : false
                                                                }
                                                                onClick={() => onChangeAddFilter('volumes', value.uuid)}
                                                            />
                                                        </div>
                                                        <div className={'content'}>
                                                            <p>{value.name}</p>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                            <div className={'modal-buttons'} style={{ display: 'block', width: '100%' }}>
                                                <GlobalButtonFinal className={'btn-circle backgroundBlue'} onClick={() => showFilteredProducts()}>
                                                    <FormattedMessage
                                                        {...messages.products_filter_modal_groups_button_show_results}
                                                    ></FormattedMessage>
                                                </GlobalButtonFinal>
                                                <GlobalButtonFinal
                                                    className={'btn-circle borderLightGray'}
                                                    onClick={() => onChangeAddFilter('clients', null)}
                                                    style={{ marginLeft: '5px' }}
                                                >
                                                    <FormattedMessage
                                                        {...messages.products_filter_modal_groups_button_clear_filter}
                                                    ></FormattedMessage>
                                                </GlobalButtonFinal>
                                            </div>
                                        </>
                                    ) : null}
                                </ul>
                            ) : null}
                        </div>
                    </div>
                </ModalWindowRightSide>
            </ModalsWrapper>

            {disabledFilters ?
                <ReactTooltip /> : null
            }


            <DialogDeleteGroups
                editAllUUID={editAllUUID}
                toggleDelete={(toggleDelete: () => void) => {
                    handleClickDelete = toggleDelete;
                }}
                loadProductsFiltersAPI={loadProductsFiltersAPI}
                changeEditAllUUID={changeEditAllUUID}
                triggerWarning={triggerWarning}
            />

            <DialogAlert
                toggleAlert={(toggleAlert: () => void) => {
                    handleTriggerWarning = toggleAlert;
                }}
                alertType={'error'}
                message={warningMessage}
            />
        </>
    );
}
