import React, {useCallback, useEffect, useState} from 'react';
import {GlobalButtonFinal, ModalsWrapper, ModalWindow} from "../GlobalStyles";
import {getCookie, setCookie} from "cookies-next";
import { useRouter } from 'next/router';
import * as Sentry from "@sentry/nextjs";

export default function DialogAcceptTeamInvite ({toggleInvite,
                                                    inviteTeamUUID}) {

    const router = useRouter();
    const toggleInviteInside = useCallback((): void => {
        if(modalInviteTeamActive){
            changeModalInviteTeamActive(false);
        }else{

            changeModalInviteTeamActive(true);
        }


    }, []);
    useEffect(() => {

        toggleInvite && toggleInvite(toggleInviteInside);
    }, [toggleInvite, toggleInviteInside]);

    const [inviteTeamUUIDInner, setInviteTeamUUIDInner] = useState(null);


    useEffect(() => {
        if(inviteTeamUUID){
            getAllTeamInvitations();
        }
    }, [inviteTeamUUID]);

    const getAllTeamInvitations = () =>{


        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"team-invitations", {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': "Bearer "+  getCookie('userTeam'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data){
                if(data.message !== undefined){
                    Sentry.captureException("DialogAcceptTeamInvite team-invitations"+": "+ data.message + " : " + getCookie("userMail"));
                }

             if(data.length === 0){
                 window.open("/", "_self");
                 changeModalInviteTeamActive(false);
             }
                data.map((value, index) => {

                  if(value.uuid === inviteTeamUUID){
                      setInviteTeamUUIDInner(value.team.uuid);
                      setInvitationUUID(value.uuid);
                      setTeamName(value.team.name);
                  }
                })

            });
    }



    const acceptInvite = () =>{

        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"team-invitations/"+invitationUUID+"/accept", {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json',
                'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data){
                if(data.message !== undefined){
                    Sentry.captureException("DialogAcceptTeamInvite team-invitations"+invitationUUID+"/accept"+": "+ data.message + " : " + getCookie("userMail"));
                }
                if(data.message === undefined){

                    fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"auth/switch-team/"+inviteTeamUUIDInner, {
                        method: 'POST',
                        headers: {
                            'Content-type': 'application/json',
                            'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
                        },
                    })
                        .then(response => response.json())
                        .then(function(data){
                            if(data.message !== undefined){
                                Sentry.captureException("AcceptInvite auth/switch-team/"+inviteTeamUUIDInner+": "+ data.message + " : " + getCookie("userMail"));
                            }
                            //
                            if(data.token !== undefined && data.token !== null){
                                // window.localStorage.setItem('userTeam', data.token);
                                // window.localStorage.setItem('userTeamUUID', inviteTeamUUID);
                                setCookie("userTeam", data.token,{maxAge: 10 * 365 * 24 * 60 * 60});
                                setCookie("userTeamUUID",inviteTeamUUIDInner,{maxAge: 10 * 365 * 24 * 60 * 60} );

                                window.open(window.location.pathname);
                            }
                        });

                }
            });
    }

    const declineInvite = () =>{
        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"team-invitations/"+invitationUUID+"/reject", {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json',
                'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data){
                if(data.message !== undefined){
                    Sentry.captureException("RejecetInvite auth/switch-team/"+invitationUUID+"/reject"+": "+ data.message + " : " + getCookie("userMail"));
                }
                if(data.message === undefined){

                    changeModalInviteTeamActive(false);

                }
            });
    }



    const [invitationUUID, setInvitationUUID] = useState(false);
    const [teamName, setTeamName] = useState(false);

    const [modalInviteTeamActive, changeModalInviteTeamActive] = useState(false);

    const closeModalOutsideClick = (e) => {
        if( e.target.getAttribute("data-closemodal") === "true"){
            changeModalInviteTeamActive(false);
        }
    }

    return (
        <>


            <ModalsWrapper className={(modalInviteTeamActive) ? '' : 'hideModal'}
                           data-closemodal={true}
                           onClick={(e)=> closeModalOutsideClick(e)}
            >


                <ModalWindow id={'newTeamModal'} className={modalInviteTeamActive ? '' : ' hideModal'} >
                    <div className={'modal-header'}>
                        <h4 style={{textAlign:"center", fontSize:"22px !important",paddingBottom:"30px"}}>You have been invited to the team {teamName}, do you want to accept it ?</h4>

                    </div>
                    <br/>

                    <div className={"modal-buttons"}>
                        <GlobalButtonFinal className={"backgroundBlue btn-circle"} style={{width:"48%",float:"left", display: "inline-block"}} onClick={acceptInvite}>Accept</GlobalButtonFinal>
                        <GlobalButtonFinal className={"borderRed btn-circle"}  style={{width:"48%",marginLeft:"15px",float:"left", display: "inline-block"}}  onClick={declineInvite}>Reject</GlobalButtonFinal>

                    </div>
                </ModalWindow>


            </ModalsWrapper>


        </>
    )
}